<!--
* Articles 组件：article card
* 主要用途：用于各个文章的卡片。
-->
<template lang="pug">
  a.article_wrapper(:href="card.url" target="_self")
    .left
      img.image(:src="card['img_url'] || card['cov_img']")
    .right
      p.title {{ card.title }}
      p.type {{ card.type}}

</template>
<script>
export default {
  name: 'articleCard',
  data() {
    return {
      cardInfo: {},
      defaultImg: '@/assets/images/mediaKit/poster_default.png',
    }
  },
  props: ['card'],
}
</script>
<style lang="stylus" scoped>
.article_wrapper
  display flex
  border 1px solid #ebebeb
  &:active
    cursor pointer
  .left
    margin-right 18px
    .image
      width 160px
      border-radius 4px
      border 1px solid #fafafa
  .right
    .title
      font-size 16px
      height 80px
      color #4c4c4c
      line-height 22px
      margin-bottom 12px
    .type
      font-size 12px
      color #c2c2c2
      text-overflow ellipsis
      white-space nowrap
</style>
