import axios from 'axios';
import { dateFormatter } from '@/utils/index'

axios.defaults.withCredentials = true;
const baseUrl = 'https://intervista.youdao.com'

function getDailyInfo(lang = 'hi', date = dateFormatter(new Date()), apiversion = '1.0', update = false, client = 'mobile', duplicate = false) {
  const url = `${baseUrl}/infoline`;
  const language = `{ count: 2, langs: [['en'], ['${lang}']] }`
  const query = {
    language: encodeURIComponent(language),
    apiversion,
    update,
    client,
    date,
    duplicate,
    imei: '000000000000',
    keyfrom: 'hindi.3.8.0.android',
  }

  return axios.get(url, {
    params: {
      language: query.language,
      apiversion: query.apiversion,
      update: query.update,
      client: query.client,
      date: query.date,
      duplicate: query.duplicate,
      imei: query.imei,
      keyfrom: query.keyfrom,
    },
  });
}

function getMoreArticles(startid, apiversion = '1.0', lang = 'hi', update = 'auto', client = 'mobile', duplicate = false) {
  const url = `${baseUrl}/getArticle`;
  const language = `{ count: 2, langs: [['en'], ['${lang}']] }`;
  const query = {
    language: encodeURIComponent(language),
    apiversion,
    startid,
    update,
    client,
    duplicate,
    imei: '000000000000',
    keyfrom: 'hindi.3.8.0.android',
  }

  return axios.get(url, {
    params: {
      language: query.language,
      apiversion: query.apiversion,
      startid: query.startid,
      update: query.update,
      client: query.client,
      duplicate: query.duplicate,
      imei: query.imei,
      keyfrom: query.keyfrom,
    },
  });
}

export default {
  getDailyInfo,
  getMoreArticles,
}
