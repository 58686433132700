<template lang="pug">
.articles
  nav-bar
  .article_box
    .articles_panel(
      v-infinite-scroll="loadingMore",
      infinite-scroll-disable="busy"
      infinite-scroll-immediate-check="false"
      infinite-scroll-distance="10"
    )
      p.head {{$TT('Articles')}}
      .content
        article-card.card(
          v-for="(article, index) in cardList"
          :key="index"
          :card="article"
        )
    //- 广告边界，先暂时去掉，如果需要可恢复
    //- .ad_panel(v-if="false")
    //-   ad
  .footer_box
   dict-footer
</template>
<script>
// import data from '@/config/articles';
import dictFooter from '@/components/pc/dictFooter.vue'
import service from '@/services/index';
import infiniteScroll from 'vue-infinite-scroll';
import navBar from '@/components/pc/navBar.vue';
import { dateFormatter } from '@/utils/index'
// import ad from './components/ad.vue';
import articleCard from './components/articleCard.vue';

export default {
  name: 'Articles',
  components: {
    dictFooter, navBar, articleCard,
    // ad,
  },
  directives: { infiniteScroll },
  data() {
    return {
      cardList: [],
      loading: false,
      busy: false,
      id: 0,
    }
  },
  // 通过接口获取数据;
  mounted() {
    this.loading = true;
    this.getDailyArticles();
  },
  watch: {
    '$store.state.selectLang': {
      handler() {
        this.getDailyArticles();
      },
    },
  },
  methods: {
    getDailyArticles() {
      const date = dateFormatter(new Date());
      const lang = this.transLang(this.$store.state.selectLang);
      service.getDailyInfo(lang, date).then((res) => {
        const len = res.data.data[date].length;
        this.cardList = res.data.data[date]
        this.id = res.data.data[date][len - 1].id;
        console.log('id', this.id);
        this.loading = false;
      })
    },
    transLang(lang) {
      let result = '';
      switch (lang) {
        case 'English':
          result = 'en';
          break;
        case 'Español':
          result = 'es';
          break;
        case 'Bahasa':
          result = 'id';
          break;
        case 'العربية':
          result = 'ar';
          break;
        default:
          result = 'en';
      }
      return result;
    },
    loadingMore() {
      this.busy = true;
      this.id -= 11;
      service.getMoreArticles(this.id).then(() => {
        // const arr = this.cardList.concat(res.data.articles)
      })
    },
  },
}
</script>
<style lang="stylus" scoped>
.articles
  width 100%
  min-width 1300px
  font-family Girloy
  overflow auto
  .article_box
    width 1300px
    padding-top 100px
    margin 0 auto
    display flex
    min-height: 85vh
    .articles_panel
      width 60%
      margin 0 auto
      border 1px solid #CACACA
      .head
        height 43px
        font-size 18px
        color #333
        line-height 43px
        font-weight bold
        margin-left 10px
      .content
        .card
          padding 18px 18px 22px
    .ad_panel
      width 30%
      height 1000px
  .footer_box
    width 100%
    margin-top 50px
</style>
